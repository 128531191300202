import { createI18n, type I18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

export const SUPPORTED_LOCALES = {
  nl: {
    code: 'nl',
    name: 'Nederlands',
  },
  en: {
    code: 'en',
    name: 'English',
  },
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: false,
  messages,
})

export function useI18n(): I18n {
  return i18n
}
